import { FaWhatsapp } from "react-icons/fa";
import "./FloatingButton.css";

export default function FloatingButton() {

  return (
    <>
      <div className="maindivstickytop">
        <div className="maindivsticky1">
          <a href="https://wa.me/917606076411"><FaWhatsapp style={{ fontSize: "30px", color: "white" }} /></a>
        </div>
      </div>
    </>
  );
}
