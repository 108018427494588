import React from 'react'
import './Footer.css';
// import logo from '../../Assets/logo__2_-removebg-preview.png'

import { address } from '../../Utils/staticData';
import { email } from '../../Utils/staticData';

export default function Footer() {
  return (
<footer id="footer" className="footer">
<div className="footer-content position-relative">
  <div className="container">
    <div className="row">

      <div className="col-lg-4 col-6">
        <div className="footer-info">
          <h3>SRP DEVELOPERS</h3>
          {/* <img className='logoimg' src={logo} alt="" /> */}
          <p>{address}<br/>
            <strong>Phone:</strong> +1 5589 55488 55<br/>
            <strong>Email:</strong>{email}<br/>
          </p>
          <div className="social-links d-flex mt-3">
            <a href="/" className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter"></i></a>
            <a href="/" className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook"></i></a>
            <a href="/" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram"></i></a>
            <a href="/" className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/">About us</a></li>
          <li><a href="/">Services</a></li>
          <li><a href="/">Gallery</a></li>
          <li><a href="/">Portfolio</a></li>
          <li><a href="/">Contact</a></li>
          <li><a href="/privacy-policy">Privacy policy</a></li>
        </ul>
      </div>

      {/* <div className="col-lg-2 col-md-6 col-12 footer-links footerservices">
        <h4>Our Services</h4>
        <ul>
          <li><a href="/">Building Construction</a></li>
          <li><a href="/">House Renovation</a></li>
          <li><a href="/">Architecture Design</a></li>
          <li><a href="/">Interior Design</a></li>
          <li><a href="/">Fixing & Support</a></li>
          <li><a href="/">Painting</a></li>
        </ul>
      </div> */}

      <div className="col-lg-4 col-md-6 footer-links">
      <iframe
              title='map'
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameborder="0" style={{border:"0", width: "100%", height: "300px"}} allowfullscreen></iframe>
      </div>

    </div>
  </div>
</div>

<div className="footer-legal text-center position-relative">
  <div className="container">
    <div className="copyright">
      &copy; Copyright <strong><span>SRP Developers</span></strong>. All Rights Reserved
    </div>
    <div className="credits">
      Designed by <a href="https://www.glowtechmor.com/">GlowtechMor PVT. LTD</a>
    </div>

  </div>
</div>

</footer>
  )
}
