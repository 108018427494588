import ServiceCard from '../ServiceCard/ServiceCard';

export default function Services() {
  return (
    <>
      <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">

      <div className="section-header">
          <h2>Services</h2>
          {/* <p>These featured listings contain exclusive real estate opportunities within the city</p> */}
        </div>
 
        <div className="row gy-4">
          <ServiceCard name = "Property Buying & Selling" desc = "We help you locate perfect property for Residence/Business throughout Bhubaneswar."/>
          <ServiceCard name = "Property Development" desc = "We work on partnership to develop you land & increase property value. We take charge of development & marketing, rest you enjoy profits."/>
          <ServiceCard name = "Commercial Property" desc = "We develop & Sell/Rent out commercial properties. For more details contact us."/>
        </div>
      </div>
    </section>
    </>
  )
}
