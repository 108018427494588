import './ContactTop.css'

export default function ContactTop() {
  return (
    <>
    <section id="get-started" className="get-started section-bg">
      <div className="container">

        <div className="row justify-content-between gy-4">

        <div className="col-lg-6 col-md-6 d-flex align-items-center" data-aos="fade-up">
            <div className="content">
              <h3>Who are we?</h3>
              <p>Aliquam velit deserunt autem. Inventore et saepe. Tenetur suscipit eligendi labore culpa eos. Deserunt
                porro magni qui necessitatibus dolorem at animi cupiditate.</p>
                <p>Rem id rerum. Debitis deserunt quidem delectus expedita ducimus dolor. Aut iusto ipsa. Eos ipsum nobis
                ipsa soluta itaque perspiciatis fuga ipsum perspiciatis. Eum amet fugiat totam nisi possimus ut delectus
                dicta.
               </p> 
              <p>Aliquam velit deserunt autem. Inventore et saepe. Tenetur suscipit eligendi labore culpa eos. Deserunt
                porro magni qui necessitatibus dolorem at animi cupiditate.</p>
                <p>Rem id rerum. Debitis deserunt quidem delectus expedita ducimus dolor. Aut iusto ipsa. Eos ipsum nobis
                ipsa soluta itaque perspiciatis fuga ipsum perspiciatis. Eum amet fugiat totam nisi possimus ut delectus
                dicta.
               </p> 
            </div>
          </div>
     

          <div className="col-lg-6 col-md-6 d-flex align-items-center" data-aos="fade-up">
            <div className="content">
              <h3>How can we help you?</h3>
              <p>Aliquam velit deserunt autem. Inventore et saepe. Tenetur suscipit eligendi labore culpa eos. Deserunt
                porro magni qui necessitatibus dolorem at animi cupiditate.</p>
                <p>Rem id rerum. Debitis deserunt quidem delectus expedita ducimus dolor. Aut iusto ipsa. Eos ipsum nobis
                ipsa soluta itaque perspiciatis fuga ipsum perspiciatis. Eum amet fugiat totam nisi possimus ut delectus
                dicta.
               </p> 
              <p>Aliquam velit deserunt autem. Inventore et saepe. Tenetur suscipit eligendi labore culpa eos. Deserunt
                porro magni qui necessitatibus dolorem at animi cupiditate.</p>
                <p>Rem id rerum. Debitis deserunt quidem delectus expedita ducimus dolor. Aut iusto ipsa. Eos ipsum nobis
                ipsa soluta itaque perspiciatis fuga ipsum perspiciatis. Eum amet fugiat totam nisi possimus ut delectus
                dicta.
               </p> 
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}
