import './LocationCard.css'

export default function LocationCard({office}) {
  return (
    <>
       <div class="col-lg-12" data-aos="fade-up" data-aos-delay="100">
            <div class="card-item">
              <div class="locationcardrow">

                <div class="col-xl-5 col-4 locationcard">
                  <div class="card-bg"></div>
                </div>

                <div class="col-xl-7 col-8 d-flex align-items-start">
                  <div class="card-body">
                    <h4 class="card-title">Office Name: {office.name}</h4>
                    <p>Office Address: {office.address}</p>
                    {/* <p>Opening Time</p> */}
                    {/* <p>Closing Time</p> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
    </>
  )
}
