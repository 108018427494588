import React from 'react'
import Testimonial from '../../Components/Testimonial/Testimonial'
import Inquiry from '../../Components/Inquiry/Inquiry'
import Client from '../../Components/Client/Client'
import ApartmentType from '../../Components/ApartmentType/ApartmentType'
import FeaturedListing from '../../Components/FeaturedListing/FeaturedListing'
// import PropertiesRent from '../../Components/PropertiesRent/PropertiesRent'
import Services from '../../Components/Services/Services'
import BrandParteners from '../../Components/BrandPartners/BrandParteners'
import Locations from '../../Components/Locations/Locations'
import BankPartners from '../../Components/BankPartners/BankPartners'
import HeroSection from '../../Components/HeroSection/HeroSection'
import FloatingButton from '../../Components/FloatingButton/FloatingButton';


export default function Home() {
  return (
    <>
     <HeroSection/>
     <FloatingButton/>
     <BrandParteners/>
     <Services/>
     <FeaturedListing title = "Discover Our Featured Listings"/>
     <ApartmentType/>
     {/* <PropertiesRent/> */}
     <Inquiry/>
     <Client/>
     <BankPartners/>
     <Locations/>
     <Testimonial/> 
    </>
  )
}
