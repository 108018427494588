import ListingCard from "../ListingCard/ListingCard";
import fetchData from '../../Utils/FetchData';
import { featuredListingData } from "../../Utils/staticData";
export default function FeaturedListing({title}) {
  const featuredlisting = fetchData("FeaturedListing");

  return (
    <>
     <section className="section-bg">
      <div className="container">

        <div className="section-header">
          <h2>{title}</h2>
          <p>These featured listings contain exclusive real estate opportunities within the city</p>
        </div>

        <div className="row">
          {
            featuredlisting && featuredlisting.length > 0 ?
            featuredlisting.map((featuredlisting)=>(
              <ListingCard data = {featuredlisting}/>
            ))
            :
            featuredListingData.map((featuredlisting)=>(
              <ListingCard data = {featuredlisting}/>
            ))
          }
           

        </div>
       
      </div>
    </section> 
    </>
  )
}
