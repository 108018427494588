import './BankPartners.css'
import fetchData from '../../Utils/FetchData';
import { bankData } from '../../Utils/staticData';

export default function BankPartners() {

  const bank = fetchData("Bank");

  console.log("bank data",bank);
  return (
    <>
      <section id="partnerbanks" class="partnerbanks">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Partner Banks</h2>
          </div>

          <div class="gy-4">
              <div class="bankimages">
                {
                  bank && bank.length > 0 ?
                  bank.map((bank)=>(
                    <img key={bank.id} src={bank.imageUrl} alt="bank" />
                  ))
                  :
                  bankData.map((bank)=>(
                    <img key={bank.id} src={bank.imageUrl} alt="bank" />
                  ))
                }
               
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
