import { useState } from 'react';
import ContactTop from '../../Components/ContactTop/ContactTop';
import Top from '../../Components/Top/Top';
import './Contact.css';
import ContactForm  from '../../Utils/ContactForm';

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState({});
  
  const [errors, setErrors] = useState({});

  const [name,setName] = useState('');
  const [email,setEmail] = useState("");
  const [number,setNumber] = useState('');
  const [message,setMessage] = useState('');

  const validation = () => {
    const newErrors = {};
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[\d+\-\s()]*$/;
  
  
    if (!name) {
      newErrors.name = 'Name is required';
    }
  
    if (!number) {
      newErrors.number = 'Mobile Number is required';
    } else if (!phonePattern.test(number)) {
      newErrors.number = 'Please enter correct Correct Mobile Number';
    }
  
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
      newErrors.email = 'Please enter your Institute Email ID';
    }
  
    if (!message) {
      newErrors.message = 'Message is required';
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const submitContactUsForm = async (e) =>{
    e.preventDefault();

    if(validation()){
        setLoading(true);
        setLoadingMessage({ type: 'wait', text:"Wait Until Your Data is Submitted!..."});

        const {type,text,loading} = await ContactForm({name,email,number,message});

        setLoadingMessage({ type: type, text: text });
        setTimeout(() => {
          setLoading(loading);
        }, 5000);

        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
    }
    else{
      window.location.reload()
    }
  }

  return (
    <>
      <Top title="Contact"/>
      <ContactTop/>
      <section id="contact" className="contact">
      <div className="container" >

        <div className="row gy-4">

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-map"></i>
              <h3>Our Address</h3>
              <p>Ghatika, Bhubaneswar</p>
            </div>
          </div>

          <div className="col-lg-3 col-6" data-aos="fade-up" data-aos-delay="100">
            <div className="info-item d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-envelope"></i>
              <h3>Email Us</h3>
              <p>info@srpdevelopers.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-6" data-aos="fade-up" data-aos-delay="100">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-telephone"></i>
              <h3>Call Us</h3>
              <p>+1 5589 55488 55</p>
            </div>
          </div>

        </div>

        <div className="row gy-4 mt-1">

          <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <iframe
              title='map'
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameborder="0" style={{border:"0", width: "100%", height: "384px"}} allowfullscreen></iframe>
          </div>

          <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <form className="php-email-form">
              <div className="row gy-4">
                <div className="col-lg-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required
                   value={name} onChange={(e)=>{setName(e.target.value)}}/>
                   {errors.name && (<span className="contactspan">{errors.name}</span>)}
                </div>
                <div className="col-lg-6 form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required
                    value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                     {errors.email && (<span className="contactspan">{errors.email}</span>)}
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="number" id="subject" placeholder="Mobile Number" required
                  value={number} onChange={(e)=>{setNumber(e.target.value)}}/>
                   {errors.number && (<span className="contactspan">{errors.number}</span>)}
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required
                  value={message} onChange={(e)=>{setMessage(e.target.value)}}></textarea>
                   {errors.message && (<span className="contactspan">{errors.message}</span>)}
              </div>
              {/* <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div> */}
              <div className="text-center">
                {
                  loading ? 
                  <p style={{ color: loadingMessage.type === 'success' ? 'green' : loadingMessage.type === 'error' ? 'red' :'yellow'}}>{loadingMessage.text}</p>
                  :
                  <button type="submit" onClick={submitContactUsForm}>Send Message</button>
                }
                </div>
            </form>
          </div>

        </div>

      </div>
    </section> 
    </>
  )
}
