import "./ClientCard.css";
export default function ClientCard({client}) {
  return (
    <>
      <div class="col-lg-3 col-md-3 col-6" data-aos="fade-up" data-aos-delay="100">
        <div class="client-item">
          <div class="client-img">
            <img src={client.imageUrl} alt="clientImage" />
          </div>
          <div class="client-text">
            <h2>{client.data.name}</h2>
            <p>{client.data.designation}</p>
          </div>
          <div class="client-social">
            <a class="social-tw" href={client.data.twitter}><i class="fab fa-twitter"></i></a>
            <a class="social-fb" href={client.data.fb}><i class="fab fa-facebook-f"></i></a>
            <a class="social-li" href={client.data.linkedin}><i class="fab fa-linkedin-in"></i></a>
            <a class="social-in" href={client.data.insta}><i class="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </>
  );
}
