import React from 'react'

export default function HeroSectionSlide({herosection}) {
  return (
    <>
       <div className="position-relative herosectiondiv">
                <img className="img-fluid carouselimg" src={herosection.imageUrl} alt="" style={{width:"100%"}}/>
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-start" style={{background: "rgba(24, 29, 56, .7)"}}>
                        <div className="justify-content-center ">
                            <div className="col-sm-12 col-lg-12 herosectioninnerdiv">
                                <h5 className="text-white text-uppercase mb-3">Ongoing Projects</h5>
                                <h1 className="herosectionheading text-white" data-aos="fade-down">Explore our Projects.</h1>
                                <p className="text-white mb-4 pb-2">We provide you with best opportunity for investment in Odisha. Get the complete details of our Projects. Connect with us now.</p>
                                <div className="buttonclass mx-auto my-auto flex">
                                   <button type="submit">Know More</button>
                                </div>
                            </div>
                        </div>
                </div>
        </div> 
    </>
  )
}
