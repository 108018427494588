import { lazy,Suspense,useEffect } from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router , Routes, Route } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import MobileViewHeader from './Components/MobileViewHeader/MobileViewHeader';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
// import About from './Pages/About/About';
import Service from './Pages/Service/Service';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Project from './Pages/Project/Project';
import Portfolio from './Pages/Portfolio/Portfolio';
import ServiceDetails from './Pages/ServiceDetails/ServiceDetails';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register'
import Upload from './Pages/Upload/Upload';

const About = lazy(() => import("./Pages/About/About"));

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<Suspense fallback={<div>Loading...</div>}><About/></Suspense>}/>
          <Route path='/service' element={<Service/>}/>
          <Route path='/projects' element={<Project/>}/>
          <Route path='/portfolio' element={<Portfolio/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/upload" element={<Upload/>}/>
          <Route path='/service-detail' element={<ServiceDetails/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        </Routes>
        <Footer/>
        <MobileViewHeader/>
      </Router>
      
    </div>
  );
}

export default App;
