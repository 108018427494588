import "./TestimonialCard.css";

export default function TestimonialCard({testimonial}) {
  const stars = Array(testimonial.data.rating).fill(null);
  return (
    <>
      <div class="testimonial-item">
        <div className="testimonialinnerdiv d-flex">
          <div className="testimonialimgdiv">
            <img src={testimonial.imageUrl} class="testimonial-img" alt="" />
          </div>

          <div className="testimonialinfodiv">
            <h3>{testimonial.data.name}</h3>
            <h4>{testimonial.data.designation}</h4>
            <div class="stars">
              {stars.map((_, index) => (
                <i key={index} className="bi bi-star-fill"></i>
              ))}
            </div>
          </div>
        </div>

        <p>
          <i class="bi bi-quote quote-icon-left"></i>
            {testimonial.data.description}
          <i class="bi bi-quote quote-icon-right"></i>
        </p>
      </div>
    </>
  );
}
