import {useState} from 'react'
import UploadData from '../../Utils/UploadData';

export default function UploadTestimonial({ setLoadingState, setLoadingMessage }) {
    const [file, setFile] = useState(null);
    const [name,setName] = useState("");
    const [designation,setDesignation] = useState("");
    const [rating,setRating] = useState("");
    const [description,setDescription] = useState("");
    const handleSubmitOffice = async () => {

        const formData = new FormData();
        formData.set("name", name);
        formData.set("designation", designation);
        formData.set("rating",rating);
        formData.set("description",description);

        const data = {};
        for (const [key, value] of formData.entries()) {
        data[key] = value;
        }


          setLoadingState(true);
          setLoadingMessage("", "Please wait until your data is submitted!...");
  
          const {type,text,loading} = await UploadData({collectionName :"Testimonial",file,data})
  
          setLoadingMessage(type, text);
          setTimeout(() => {
            setLoadingState(loading);
          }, 5000);
  
    };
  
    
    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
      }
    };
  return (
    <>
    <div>
      <div className="uploadimagerow">
        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
            <h3>Testimonial Section: </h3>

            <input type="text" id="officeName" placeholder="Enter Name" 
            value={name} onChange={(e) => setName(e.target.value)}/>

            <label htmlFor="imageInput">Upload Image:</label>
            <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange}required />

            <label htmlFor="Designation">Designation</label>
            <input type="text" id="Designation" placeholder="Enter Designation"
            value={designation} onChange={(e) => setDesignation(e.target.value)}/>

            <select name="rating" id="rating" value={rating} onChange={(e)=>{setRating(e.target.value)}}>
                <option value="">Select Rating</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>

            <label htmlFor="description">Description</label>
            <input type="text" id="description" placeholder="Enter Description"
            value={description} onChange={(e) => setDescription(e.target.value)}/>

            <button className="uploadimagebtn" onClick={handleSubmitOffice} style={{ marginTop: "5px", borderRadius: "5px" }}>
              Upload
            </button>
          </div>
        </div>

        <div className="uploadimagecolumn">
          <img className="uploadimageimage" src="/" alt="testimonial"></img>
        </div>
      </div>
    </div>
    </>
  )
}
