import { useEffect} from 'react';
import './BrandPartners.css'
import fetchData from '../../Utils/FetchData';
import { brandData } from '../../Utils/staticData';

export default function BrandParteners() {
    useEffect(()=>{
        var copy = document.querySelector(".logos-slide").cloneNode(true);
        document.querySelector(".logos").appendChild(copy);
    },[]);

    const brand =  fetchData("Brand");

    console.log(brand);
  
  return (
    <>
    <div class="logos">
      <div class="logos-slide">
        {
          brand && brand.length > 0 ?
          brand.map((brand)=>(
            <img key={brand.id} src={brand.imageUrl} alt='brand'/>
          ))
          :
          brandData.map((brand)=>(
            <img key={brand.id} src={brand.imageUrl} alt='brand'/>
          ))
        }
       
      </div>
    </div>
    </>
  )
}
