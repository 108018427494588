import axios from "axios";
import {API} from '../Utils/staticData'
const InquiryForm = async (name,email,number,requirements)=>{
    
    try{
        await axios.post(`${API}/inquiry`, name,email,number,requirements);

        return {type :"success",text :"Form Submitted Successfully",loading: false}
      
    }

    catch(error){
        return {type :"error",text : error.message ,loading: false}
    } 
}

export default InquiryForm;