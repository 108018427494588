import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import fetchData from '../../Utils/FetchData';
import { testimonialData } from "../../Utils/staticData";

export default function Testimonial() {
  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    dots:true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonial = fetchData("Testimonial");

  console.log("testimonial data",testimonial);
  return (
    <>
     <section id="testimonials" className="testimonials section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Testimonials</h2>
          <p>Quam sed id excepturi ccusantium dolorem ut quis dolores nisi llum nostrum enim velit qui ut et autem uia
            reprehenderit sunt deleniti</p>
        </div>

        <div className="testimonialrow">

        <Slider {...settings} className='testimonialslider'>
             {
              testimonial && testimonial.length > 0 ?
              testimonial.map((testimonial)=>(
                <TestimonialCard testimonial = {testimonial}/>
              ))
              :
              testimonialData.map((testimonial)=>(
                <TestimonialCard testimonial = {testimonial}/>
              ))
             }
        </Slider> 
        </div>
      </div>
    </section> 
    </>
  )
}
