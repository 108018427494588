import {useState} from 'react'
import UploadData from '../../Utils/UploadData';

export default function UploadProjects({ setLoadingState, setLoadingMessage }) {
    const [file, setFile] = useState(null);
    const [data] = useState(null);

    const handleSubmitProjects = async () => {
          if(!file){
            alert("Please Select file");
          }
          else{
          setLoadingState(true);
          setLoadingMessage("", "Please wait until your data is submitted!...");
  
          const {type,text,loading} = await UploadData({collectionName :"Projects",file,data})
  
          setLoadingMessage(type, text);
          setTimeout(() => {
            setLoadingState(loading);
          }, 5000);
          }
    };
  
    
    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
      }
    };
  return (
    <>
    <div>
      <div className="uploadimagerow">
        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
            <h3>Projects Section: </h3>

            <label htmlFor="imageInput">Upload Image:</label>
            <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange}required/>

            <button className="uploadimagebtn" onClick={handleSubmitProjects} style={{ marginTop: "5px", borderRadius: "5px" }}>
              Upload
            </button>
          </div>
        </div>

        <div className="uploadimagecolumn">
          <img className="uploadimageimage" src="/" alt="projects"></img>
        </div>
      </div>
    </div>
    </>
  )
}
