import AboutSection from "../../Components/AboutSection/AboutSection";
import ApartmentType from "../../Components/ApartmentType/ApartmentType";
import FeaturedListing from "../../Components/FeaturedListing/FeaturedListing";
import Top from "../../Components/Top/Top";

export default function Portfolio() {
  return (
    <>
      <Top title = "Portfolio"/>
      <AboutSection/>
      <FeaturedListing title = "Investment Plots"/>
      <FeaturedListing title = "Duplexes"/>
      <ApartmentType/>
      <FeaturedListing title = "Commercial Properties"/>
    </>
  )
}
