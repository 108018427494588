import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="termsdiv">
        <div className="page">
          <div id="terms-and-conditions">
            <h1>Privacy Policy</h1>
            <ol>
              <li>
                <b>Who are we?</b>
              </li>

              <p>About SRP Developers</p>

              <li>
                <b>Our Vision: Providing the Best Real Estate Opportunities</b>
              </li>

              <p>
                SRP Developer was founded with a vision of offering the finest
                real estate opportunities for investors. Our projects are
                strategically located in Odisha, a state known for its vibrant
                growth and promising real estate landscape.
              </p>

              <li>
                <b>Our Expertise: Over a Decade of Experience</b>
              </li>

              <p>
                The partners behind SRP Developer have a combined experience of
                more than 10 years in the real estate industry. During this
                time, we have gained a deep understanding of the real challenges
                faced by the real estate market. This extensive experience has
                equipped us with the knowledge and expertise to navigate the
                complexities of the industry and deliver exceptional results for
                our clients.
              </p>

              <li>
                <b>Our Commitment: Emotion-Driven, Investment-Worthy Homes</b>
              </li>

              <p>
                At SRP Developer, we recognize the emotional significance of
                owning a home. We are dedicated to providing our clients with
                projects that not only fulfill their dream of a sweet home but
                also serve as a wise investment. Our focus is on creating
                properties that strike the perfect balance between personal
                fulfillment and financial security. Stay connected with us to
                never miss out on the latest updates and exciting investment
                opportunities. Our team is committed to keeping you informed
                about our upcoming projects and ensuring that you have access to
                the best real estate options in the region.
              </p>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}
