import React from 'react'
import './ApartmentType.css';
import project1 from '../../Assets/plots1.jpg'
import project2 from '../../Assets/3d architectural rendering services apartment visualization.jpg';
import project3 from '../../Assets/joyville-hadapsar-annexe-plots-banner.jpg';
import project4 from '../../Assets/sudhakar-plot-layout-1.jpg';

export default function ApartmentType() {
  return (
    <>
    <section id="apartmenttype" className="apartmenttype">
      <div className="container">

        <div className="section-header">
          <h2>Explore our Projects</h2>
          <p>Explore all the different types of apartments so you can choose the best option for you</p>
        </div>

        <div className="gallery">

        <div className="box col-2"  data-aos="zoom-in">
          <img src={project1} alt=""/>
        </div>

        <div className="box mobile-col-2"  data-aos="zoom-in">
          <img src={project2} alt=""/>
        </div>

        <div className="box row-2 mobile-col-2"  data-aos="zoom-in">
          <img src={project3} alt=""/>
        </div>

        <div className="box  mobile-col-2"  data-aos="zoom-in">
          <img src={project4} alt=""/>
        </div>

        <div className="box col-2"  data-aos="zoom-in">
          <img src={project1} alt=""/>
          {/* <div className="text">
            <h3>Apartment</h3>
            <p>20 Properties</p>
          </div> */}
        </div> 

      </div>
        
      </div>
    </section> 
    </>
  )
}
