import './Top.css';
import { Link } from 'react-router-dom';
export default function Top({title}) {
  return (
    <>
      <div class="breadcrumbs d-flex align-items-center">
      <div class="container position-relative d-flex flex-column align-items-center" data-aos="fade">

        <h2>{title}</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
          <li>{title}</li>
        </ol>

      </div>
    </div>
    </>
  )
}
