import {useState,useEffect} from 'react'
import './Header.css';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import logo from '../../Assets/logo.png';

import home from '../../Assets/icons/home.png';
import about from '../../Assets/icons/about.png';
import service from '../../Assets/icons/service.png';
import gallery from '../../Assets/icons/gallery.png';
import portfolio from '../../Assets/icons/portfolio.png';
import contact from '../../Assets/icons/contact.png';


import { address } from '../../Utils/staticData';
import { email } from '../../Utils/staticData';

export default function Header() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    const handleScroll = () => {
        if (window.innerWidth < 992) {
            if (window.scrollY > 45) {
                document.querySelector('.fixed-top').classList.add('bg-white', 'shadow');
            } else {
                document.querySelector('.fixed-top').classList.remove('bg-white', 'shadow');
            }
        } else {
            if (window.scrollY > 45) {
                document.querySelector('.fixed-top').classList.add('bg-white', 'shadow');
                document.querySelector('.fixed-top').style.top = '-45px';
            } else {
                document.querySelector('.fixed-top').classList.remove('bg-white', 'shadow');
                document.querySelector('.fixed-top').style.top = '0';
            }
        }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <nav className="navbar fixed-top">
      
    <div className="top-bar w-100 h-20 row gx-0 align-items-center d-none d-lg-flex" style={{backgroundColor:"#303441"}}> 
            <div className="col-lg-6 px-5 text-start">
                <small><i className="fa fa-map-marker-alt me-2"></i>{address}</small>
                <small className="ms-4"><i className="fa fa-envelope me-2"></i>{email}</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <a className="text-body ms-3" href="/"><i className="fab fa-facebook-f"></i></a>
                <a className="text-body ms-3" href="/"><i className="fab fa-twitter"></i></a>
                <a className="text-body ms-3" href="/"><i className="fab fa-linkedin-in"></i></a>
                <a className="text-body ms-3" href="/"><i className="fab fa-instagram"></i></a>
                <a className="text-body ms-3" href="/login"><FaRegUserCircle/></a>
            </div>
    </div>

    <div className="navbarcontainer">
      <div className="logo col-lg-3 col-6">
          <img src={logo} alt="logo" />
      </div>

      <div className="menu-icon col-lg-0 col-6" onClick={handleShowNavbar}>
        {showNavbar ? <IoClose/> : <GiHamburgerMenu/>}
      </div>

      <div className={`nav-elements  ${showNavbar && "active"} col-6 col-lg-9` }>
        <ul>
          <li>
            <NavLink to="/" onClick={handleShowNavbar}><img src={home} alt="" />Home</NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={handleShowNavbar}><img src={about} alt="" />About</NavLink>
          </li> 
          <li>
            <NavLink to="/service" onClick={handleShowNavbar}><img src={service} alt="" />Services</NavLink>
          </li>
          <li>
            <NavLink to="/projects" onClick={handleShowNavbar}><img src={gallery} alt="" />Gallery</NavLink>
          </li>
          <li>
            <NavLink to="/portfolio" onClick={handleShowNavbar}><img src={portfolio} alt="" />Portfolio</NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={handleShowNavbar}><img src={contact} alt="" /> Contact</NavLink>
          </li>
          
        </ul>
      </div>

    </div>
  </nav>
  )
}
