import { Link } from 'react-router-dom';
import './ServiceCard.css';

export default function ServiceCard({name,desc}) {
  return (
    <>
       <div className="col-lg-4 col-md-6 col-12 " data-aos="fade-up" data-aos-delay="100">
            <div className="service-item d-lg-block d-flex col-12 gap-3 lg-p-4 py-3">
              
              <div className="icon col-lg-12 col-5">
                <i className="fa-solid fa-mountain-city"></i>
              </div>

              <div className='col-lg-12 col-10 px-3'>
              <h3>{name}</h3>
              <p>
                {desc}
              </p>
              <Link to="/service-detail" className="readmore stretched-link">Read more <i
                  class="bi bi-arrow-right"></i></Link>
              </div>
              
            </div>
        </div>
    </>
  )
}
