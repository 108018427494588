import LocationCard from '../LocationCard/LocationCard'
import './Locations.css'
import fetchData from '../../Utils/FetchData';
import { officeData } from '../../Utils/staticData';

export default function Locations() {

  const office = fetchData("Office");

  console.log("office data",office);
  return (
    <>
     <section id="locations" class="locations">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Office Locations</h2>
          {/* <p>Nulla dolorum nulla nesciunt rerum facere sed ut inventore quam porro nihil id ratione ea sunt quis dolorem dolore earum</p> */}
        </div>

        <div class="row gy-4">
            {
              office && office.length > 0 ?
              office.map((office)=>(
              <LocationCard key={office.id} office = {office.data}/>
              ))
              :
              officeData.map((officeData)=>(
                <LocationCard key={officeData.id} office={officeData}/>
              ))
            }
           
            {/* <LocationCard/> */}
        </div>

      </div>
    </section> 
    </>
  )
}
