import ClientCard from "../ClientCard/ClientCard";
import "./Client.css";
import fetchData from '../../Utils/FetchData';
import { clientData } from "../../Utils/staticData";

export default function Client() {
  const client = fetchData("Client");

  console.log("client data",client);
  return (
    <>
      <div class="client">
        <div class="container">
          <div className="section-header">
            <h2>Our Clients</h2>
            {/* <p>Quam sed id excepturi ccusantium dolorem ut quis dolores nisi llum nostrum enim velit qui ut et autem uia reprehenderit sunt deleniti</p> */}
          </div>
          <div class="row">

            {
              client && client.length > 0 ?
              client.map((client)=>(
                <ClientCard key={client.id} client={client}/>
              ))
              :
              clientData.map((client)=>(
                <ClientCard client={client}/>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}
