import './ProjectCard.css'
// import construction from '../../Assets/joyville-hadapsar-annexe-plots-banner.jpg'

export default function ProjectCard({projects}) {
  return (
    <>
     <div class="col-lg-4 col-6 portfolio-item filter-construction" data-aos="zoom-in" data-aos-delay="100">
              <div class="portfolio-content h-100">
                <img src={projects.imageUrl} class="img-fluid" alt=""/>
                {/* <div class="portfolio-info">
                  <h4>Construction 1</h4>
                  <p>Lorem ipsum, dolor sit amet consectetur</p>
                  <a href="assets/img/projects/construction-1.jpg" title="Construction 1"
                    data-gallery="portfolio-gallery-construction" class="glightbox preview-link"><i
                      class="bi bi-zoom-in"></i></a>
                  <a href="project-details.html" title="More Details" class="details-link"><i
                      class="bi bi-link-45deg"></i></a>
                </div> */}
              </div>
     </div>
    </>
  )
}
