import React,{useState}from 'react';
import './MobileViewHeader.css';
import {Link} from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { MdAddCall } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import { MdOutlineAccountCircle } from "react-icons/md";

import { address } from '../../Utils/staticData';
import { email } from '../../Utils/staticData';
import { mobile } from '../../Utils/staticData';

export default function MobileViewHeader() {
    const [isAddresOpen,setIsAddressOpen] = useState(false);
    const [isCallUsOpen,setisCallUsOpen]=useState(false);
    const [isEmailOpen,setisEmailOpen]=useState(false);

    const addressMenu = () => {
      setisCallUsOpen(false);
      setIsAddressOpen(!isAddresOpen);
      setisEmailOpen(false);
    };

    const callusMenu = () => {
      setisCallUsOpen(!isCallUsOpen);
      setIsAddressOpen(false);
      setisEmailOpen(false);
    };

    const emailMenu = () => {
      setIsAddressOpen(false);
      setisEmailOpen(!isEmailOpen);
      setisCallUsOpen(false);
    };



    const handleCopyClick = (text) => {
        const tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert(`${text} copied to clipboard`);
      };

  return (
    <>
    <div className='mobileviewheaderdiv'>
      <div className="mobileviewheaderrow">

            <div className="newheaaderrow1column1" onClick={addressMenu}>
            <FaLocationDot style={{height:"40px",width:"100%",color:"white"}}/>
            </div>

            <div className="newheaaderrow1column1" onClick={callusMenu}>
            <MdAddCall style={{height:"40px",width:"100%",color:"white"}}/>
            </div>

            <div className="newheaaderrow1column1" onClick={emailMenu}>
            <IoMailOpenOutline style={{height:"40px",width:"100%",color:"white"}}/>
            </div>

            <div className="newheaaderrow1column1">
            <Link style={{color:"white"}} to="/login"><MdOutlineAccountCircle style={{height:"40px",width:"100%",color:"white"}}/></Link>
            </div>
      </div>
    </div>
    {
      isAddresOpen &&
      <>
      <div className="callusdiv" >
      <button className='modelcloseButton1' onClick={()=>{setIsAddressOpen(false)}}>
        &times;
      </button>
        <p className='callusdivpara'>Address</p>
        <p className='callusdivpara'>{address}</p>
        <button className='mobilecopynumber' onClick={() => handleCopyClick(address)}>Copy</button>
      </div>
</>  
    }
    {
        isCallUsOpen &&
        <>
              <div className="callusdiv" >
              <button className='modelcloseButton1' onClick={()=>{setisCallUsOpen(false)}}>
                &times;
              </button>
                <p className='callusdivpara'>Call Us</p>
                <p className='callusdivpara'>{mobile}</p>
                <button className='mobilecopynumber' onClick={() => handleCopyClick(mobile)}>Copy</button>
              </div>
      </> 
      }
      {
        isEmailOpen && 
        <>
              <div className="emaildiv" >
              <button className='modelcloseButton1' onClick={()=>{setisEmailOpen(false)}}>
                &times;
              </button>
                 <p className='emaildivpara'>Email Us</p>
                 <p className='emaildivpara'>{email}</p>
                 <button className='emailcopybutton' onClick={() => handleCopyClick(email)}>Copy</button>
              </div>
        </>      
      }
    </>
  )
}
