import {useState} from 'react'
import UploadData from '../../Utils/UploadData';

export default function UploadClient({ setLoadingState, setLoadingMessage }) {
    const [file, setFile] = useState(null);
    const [name,setName] = useState("");
    const [designation,setDesignation] = useState("");
    const [fb,setFb] = useState("");
    const [insta,setInsta] = useState("");
    const [linkedin,setLinkedIn] = useState("");
    const [twitter,setTwitter] = useState("");

    const handleSubmitOffice = async () => {

        const formData = new FormData();
        formData.set("name", name);
        formData.set("designation", designation);
        formData.set("fb", fb);
        formData.set("insta", insta);
        formData.set("linkedin", linkedin);
        formData.set("twitter", twitter);

        const data = {};
        for (const [key, value] of formData.entries()) {
        data[key] = value;
        }


          setLoadingState(true);
          setLoadingMessage("", "Please wait until your data is submitted!...");
  
          const {type,text,loading} = await UploadData({collectionName :"Client",file,data})
  
          setLoadingMessage(type, text);
          setTimeout(() => {
            setLoadingState(loading);
          }, 5000);
  
    };
  
    
    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
      }
    };


  return (
    <>
    <div>
      <div className="uploadimagerow">
        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
            <h3>Client Section: </h3>

            <input type="text" id="clinetName" placeholder="Enter Client Name" 
            value={name} onChange={(e) => setName(e.target.value)}/>

            <label htmlFor="imageInput">Upload Image:</label>
            <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange}required />

            <label htmlFor="clientDesignation">Designation</label>
            <input type="text" id="clientDesignation" placeholder="Enter Client Designation"
            value={designation} onChange={(e) => setDesignation(e.target.value)}/>


            <label htmlFor="fb">Facebook Id</label>
            <input type="text" id="fb" placeholder="Enter Facebook Id"
            value={fb} onChange={(e) => setFb(e.target.value)}/>

            <label htmlFor="insta">Instagram Id</label>
            <input type="text" id="insta" placeholder="Enter Instagram Id"
            value={insta} onChange={(e) => setInsta(e.target.value)}/>

            <label htmlFor="linkedin">LinkedIn Id</label>
            <input type="text" id="linkedin" placeholder="Enter LinkedIn Id"
            value={linkedin} onChange={(e) => setLinkedIn(e.target.value)}/>

            <label htmlFor="twitter">Twitter Id</label>
            <input type="text" id="twitter" placeholder="Enter Twitter Id"
            value={twitter} onChange={(e) => setTwitter(e.target.value)}/>

            <button className="uploadimagebtn" onClick={handleSubmitOffice} style={{ marginTop: "5px", borderRadius: "5px" }}>
              Upload
            </button>
          </div>
        </div>

        <div className="uploadimagecolumn">
          <img className="uploadimageimage" src="/" alt="client"></img>
        </div>
      </div>
    </div>
    </>
  )
}
