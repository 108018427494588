import ServiceCard from "../../Components/ServiceCard/ServiceCard";
import Top from "../../Components/Top/Top";

export default function Service() {
  return (
    <>
     <Top title="Service"/> 
     <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4">
          <ServiceCard name = "Property Buying & Selling" desc = "We help you locate perfect property for Residence/Business throughout Bhubaneswar."/>
          <ServiceCard name = "Property Development" desc = "We work on partnership to develop you land & increase property value. We take charge of development & marketing, rest you enjoy profits."/>
          <ServiceCard name = "Commercial Property" desc = "We develop & Sell/Rent out commercial properties. For more details contact us."/>
          <ServiceCard name = "Property Buying & Selling" desc = "We help you locate perfect property for Residence/Business throughout Bhubaneswar."/>
          <ServiceCard name = "Property Development" desc = "We work on partnership to develop you land & increase property value. We take charge of development & marketing, rest you enjoy profits."/>
          <ServiceCard name = "Commercial Property" desc = "We develop & Sell/Rent out commercial properties. For more details contact us."/>
        </div>
      </div>
    </section>
    </>
  )
}
