import "./Inquiry.css";

export default function Inquiry() {
  return (
    <>
      <section id="get-started" className="get-started section-bg">
        <div className="container">
          <div className="row justify-content-between gy-4">
            <div
              className="col-lg-5 col-md-6 col-12 inquiryformdiv"
              data-aos="fade-up"
            >
              <form className="php-email-form">
                <h3>Contact Form</h3>
                {/* <p>Vel nobis odio laboriosam et hic voluptatem. Inventore vitae totam. Rerum repellendus enim linead seropark flows.</p> */}
                <div className="row gy-3">
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>

                  <div className="col-md-12 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      placeholder="Phone"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your quote request has been sent successfully. Thank you!
                    </div>

                    <button type="submit">SEND</button>
                  </div>
                </div>
              </form>
            </div>

            <div
              className="col-lg-6 col-md-6 col-12 d-flex align-items-center"
              data-aos="fade-up"
            >
              <div className="content">
                <h3>Why Choose Us</h3>
                <p>
                  At SRP Developer, we recognize the emotional significance of
                  owning a home. We are dedicated to providing our clients with
                  projects that not only fulfill their dream of a sweet home but
                  also serve as a wise investment. 
                  <p>Our focus is on creating
                  properties that strike the perfect balance between personal
                  fulfillment and financial security. Stay connected with us to
                  never miss out on the latest updates and exciting investment
                  opportunities.
                  </p> 
                  <p>
                  Our team is committed to keeping you informed
                  about our upcoming projects and ensuring that you have access
                  to the best real estate options in the region.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
