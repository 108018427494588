import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCs3hk9wzQKJKKp7VAdYvT4HaDO94IAnTw",
    authDomain: "srp-developers.firebaseapp.com",
    projectId: "srp-developers",
    storageBucket: "srp-developers.appspot.com",
    messagingSenderId: "913500454288",
    appId: "1:913500454288:web:1dadb00dbe30b5650a4715"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { db , storage, auth};
export default firebaseConfig;
