import img1 from '../Assets/sudhakar-plot-layout-1.jpg';
import img2 from '../Assets/joyville-hadapsar-annexe-plots-banner.jpg'; 

import dalmia from '../Assets/brand Partners/dalmia cement.png';
import ultratech from '../Assets/brand Partners/ultratech.png';
import ambuja from '../Assets/brand Partners/ambuja.png'; 

import icici from '../Assets/bank/icici.png'
import axis from '../Assets/bank/axis.png';
import lic from '../Assets/bank/lic.webp';
import sbi from '../Assets/bank/sbi.png';
import uco from '../Assets/bank/uco.png';

import client from '../Assets/testimonials/testimonials-5.jpg';

import testimonial1 from '../Assets/testimonial-1.jpg';

import team1 from '../Assets/team/team-1.jpg';
import team2 from '../Assets/team/team-2.jpg';
import team3 from '../Assets/team/team-3.jpg';
import team4 from '../Assets/team/team-4.jpg';
import team5 from '../Assets/team/team-5.jpg';
import team6 from '../Assets/team/team-6.jpg';

import project1 from '../Assets/plots1.jpg'
import project2 from '../Assets/3d architectural rendering services apartment visualization.jpg';
import project3 from '../Assets/joyville-hadapsar-annexe-plots-banner.jpg';
import project4 from '../Assets/sudhakar-plot-layout-1.jpg';

import listingimg from "../Assets/3d architectural rendering services apartment visualization.jpg";

export const herosectionData = [
    {
        id:1,
        imageUrl : img1
    },
    {
        id:2,
        imageUrl : img2
    }
]

export const brandData = [
    {
        id:1,
        imageUrl :dalmia
    },
    {
        id:2,
        imageUrl :ultratech
    },
    {
        id:3,
        imageUrl :ambuja
    }
]

export const bankData = [
    {
        id:1,
        imageUrl :icici
    },
    {
        id:2,
        imageUrl :axis
    },
    {
        id:3,
        imageUrl :lic
    },
    {
        id:4,
        imageUrl :sbi
    },
    {
        id:5,
        imageUrl :uco
    }
]

export const officeData = [
    {
      id:1,
      name:"SRP Developers",
      address:" Kalinganagar, Bhubaneswar"
    }
]

export const clientData = [
    {
        imageUrl:client,
        id:1,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:client,
        id:2,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:client,
        id:3,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:client,
        id:4,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
]

export const teamData = [
    {
        imageUrl:team1,
        id:1,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:team2,
        id:2,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:team3,
        id:3,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:team4,
        id:4,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:team5,
        id:5,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    },
    {
        imageUrl:team6,
        id:6,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        description:"",
        fb:"/",
        insta:"/",
        twitter:"/",
        linkedin:"/"
        }
    }    
]

export const featuredListingData = [
    {
        imageUrl:listingimg,
        data:{
            name:"Chattabara"
        }
    },
    {
        imageUrl:listingimg,
        data:{
            name:"Gothapatna"
        }
    },
    {
        imageUrl:listingimg,
        data:{
            name:"Pipilli"
        }
    },
]


export const projectData = [
  {
    id:1,
    imageUrl:project1
  },
  {
    id:2,
    imageUrl:project2
  },
  {
    id:3,
    imageUrl:project3
  },
  {
    id:4,
    imageUrl:project4
  }
]

export const testimonialData = [
    {
        imageUrl:testimonial1,
        id:1,
        data:{
        name:"Adam Phillips",
        designation:"CEO & Founder",
        rating:5,
        description:" Proin iaculis purus consequat sem cure digni ssim donec porttitoraentum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquameget nibh et. Maecen aliquam, risus at semper.",
        }
    },
    {
        imageUrl:testimonial1,
        id:2,
        data:{
        name:"Adam Phillips",
        rating:5,
        designation:"CEO & Founder",
        description:" Proin iaculis purus consequat sem cure digni ssim donec porttitoraentum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquameget nibh et. Maecen aliquam, risus at semper.",
        }
    },
    {
        imageUrl:testimonial1,
        id:3,
        data:{
        name:"Adam Phillips",
        rating:5,
        designation:"CEO & Founder",
        description:" Proin iaculis purus consequat sem cure digni ssim donec porttitoraentum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquameget nibh et. Maecen aliquam, risus at semper.",
        }
    }   
]

export const address = "Ghatika, Bhubaneswar"

export const email = "info@srpdevelopers.com"

export const mobile = "+1 5589 55488 55"

export const API = "https://srp-developers-backend.onrender.com"