import ProjectCard from '../../Components/ProjectCard/ProjectCard';
import Testimonial from '../../Components/Testimonial/Testimonial';
import Top from '../../Components/Top/Top';
import './Project.css';
import fetchData from '../../Utils/FetchData';

import { projectData } from '../../Utils/staticData';

const Project = () => {
  const projects = fetchData("Projects");

  console.log("Projects".projects);
  return (
    <>
    <Top title = "Gallery"/>
     <section id="projects" class="projects">
      <div class="container" data-aos="fade-up">

        <div class="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry"
          data-portfolio-sort="original-order">

          {/* <ul class="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-remodeling">Remodeling</li>
            <li data-filter=".filter-construction">Construction</li>
            <li data-filter=".filter-repairs">Repairs</li>
            <li data-filter=".filter-design">Design</li>
          </ul> */}

          <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {
              projects && projects.length > 0 ?
              projects.map((projects)=>(
                <ProjectCard key={projects.id} projects = {projects}/>
              ))
              :
              projectData.map((projects)=>(
                <ProjectCard key={projects.id} projects={projects}/>
              ))
            }

          </div>

        </div>

      </div>
    </section> 

    <Testimonial/>
    </>
  )
}

export default Project
