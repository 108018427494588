import React,{useState} from 'react';
import './InquiryForm.css'
import inquiryForm from '../../Utils/InquiryForm';

export default function InquiryForm() {

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState({});
  
  const [errors, setErrors] = useState({});

  const [name,setName] = useState('');
  const [email,setEmail] = useState("");
  const [number,setNumber] = useState('');
  const [requirements,setRequirements] = useState('');

  const validation = () => {
    const newErrors = {};
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[\d+\-\s()]*$/;
  
  
    if (!name) {
      newErrors.name = 'Name is required';
    }
  
    if (!number) {
      newErrors.number = 'Mobile Number is required';
    } else if (!phonePattern.test(number)) {
      newErrors.number = 'Please enter correct Correct Mobile Number';
    }
  
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
      newErrors.email = 'Please enter your Institute Email ID';
    }
  
    if (!requirements) {
      newErrors.requirements = 'Requirements is required';
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const submitInquiryForm = async (e) =>{
    e.preventDefault();

    if(validation()){
        setLoading(true);
        setLoadingMessage({ type: 'wait', text:"Wait Until Your Data is Submitted!..."});

        const {type,text,loading} = await inquiryForm({name,email,number,requirements});

        setLoadingMessage({ type: type, text: text });
        setTimeout(() => {
          setLoading(loading);
        }, 5000);

        setName("");
        setEmail("");
        setNumber("");
        setRequirements("");
    }
    else{
      window.location.reload()
    }
  }
  
  return (
    <>
    <section className="inquiryection" >
      <div className="inquiryrow1">
      <h1 className='inquiryheading'>Inquiry Form</h1>
      </div>
      <div className="inquiryrow">
    
         <div className="inquirycolumninnerdiv">
                <input type="text" className='inquiryinput' placeholder='Name'  value={name} required onChange={(e)=>{setName(e.target.value)}}/>
                {errors.name && (<span className="checkoutspan">{errors.name}</span>)}

                <input type="text" className='inquiryinput' placeholder='Contact Number'  value={number} onChange={(e)=>{setNumber(e.target.value)}}/>
                {errors.number && (<span className="checkoutspan">{errors.number}</span>)}

                <input type="email" className='inquiryinput' placeholder='Email'  value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                {errors.email && (<span className="checkoutspan">{errors.email}</span>)}

                <textarea type="text" className='inquiryinput' placeholder='Requirements'  value={requirements} onChange={(e)=>{setRequirements(e.target.value)}}/>
                {errors.requirements && (<span className="checkoutspan">{errors.requirements}</span>)}
         </div>   

          <div className="inquirycolumninnerdiv mt-4">
                {
                  loading ? 
                  <p style={{ color: loadingMessage.type === 'success' ? 'green' : loadingMessage.type === 'error' ? 'red' :'black',textAlign:"center"}}>{loadingMessage.text}</p>
                  :
                  <button className='inquiryinputbtn' onClick={submitInquiryForm}>SEND MESSAGE</button>
                }
               
          </div>
      </div>

</section> 

</>
  )
}
