import { useState,useEffect,useRef} from "react";
import "./ListingCard.css";
// import listingimg from "../../Assets/3d architectural rendering services apartment visualization.jpg";
// import { FaRulerCombined } from "react-icons/fa";
// import { FaBed } from "react-icons/fa";
// import { FaBath } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import InquiryForm from "../InquiryForm/InquiryForm";
export default function ListingCard({data}) {
  
  const[model,setmodel]=useState(false);
  const modelRef = useRef(null); 

  const openModel = ()=>{
    setmodel(!model);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modelRef.current && !modelRef.current.contains(event.target)) {
        setmodel(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [modelRef]);
  return (
    <> 
      <div className={model?"model open":"model"}>
             <InquiryForm ref={modelRef}/>
             <button className='modelcloseButton' onClick={()=>{setmodel(false)}}><IoMdClose/></button>
      </div>  

      <div class="col-lg-4 col-md-6 col-12"  data-aos="fade-up">

        <div className="listing-card col-12">

          <div className="position-relative bg-light overflow-hidden col-lg-12 col-md-12 col-4">
            <img className="img-fluid w-100" src={data.imageUrl} alt="" />
            <div className="new rounded text-white position-absolute start-0 top-0 lg-m-4 m-2 py-1 lg-px-3 px-2">
              New
            </div>
          </div>

          <div className="text-center lg-p-4 p-3 d-lg-flex col-lg-12 col-md-12 col-8">

            <div className="mb-2 col-lg-9 col-12 h-50">
              <h5 className="text-start mb-3">{data.data.name}</h5>
              {/* <div className="d-flex align-items-center gap-2">
              <FaBed className="text-gray"/> <span>4</span> <FaBath/> <span>2</span> <FaRulerCombined/> <span>1200 Sq.ft</span>
              </div> */}
            </div>

            <div className="col-lg-3 col-12 h-100">
                {/* <p className="text-body mb-2">$29.00</p> */}
                <div className="listcardbtndiv">
                  <button className="listcardbtn" type="submit" onClick={openModel}>Inquire</button>
                </div>
            </div>  
          </div>

         
        </div>
      </div>
        
    </>
  );
}
