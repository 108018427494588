
import React from 'react'
import './HeroSection.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import fetchData from '../../Utils/FetchData';
import HeroSectionSlide from '../HeroSectionSlide/HeroSectionSlide';
import { herosectionData } from '../../Utils/staticData';

const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
};

const NextArrow = (props) => {
    const { className,onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
};

export default function HeroSection() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        loop:true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        prevArrow: <PrevArrow className="prevarrow" />,
        nextArrow: <NextArrow className="nextarrow" />,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
    };

    const herosection = fetchData("HeroSection");

    console.log("herosection data",herosection);

  return (
    <>
     <div className="container-fluid p-0 lg-mb-5 header-carousel">
            <Slider {...settings} className='carousel-slider'>
            {
                herosection && herosection.length > 0 ?
                herosection.map((herosection)=>(
                    <HeroSectionSlide key={herosection.id} herosection = {herosection}/>
                ))
                :
                herosectionData.map((herosection)=>(
                    <HeroSectionSlide key={herosection.id} herosection={herosection}/>
                ))    
            }
        </Slider>
    </div>
    </>
  )
}
